.App {
  text-align: center;
  background: #000000
}

.container {
  padding-top: 1em;
  margin: 0 auto;
  background-color: #f2f2f2;
  max-width: 1200px;
}

.top-menu {
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  padding-top: 10px;
}

.main {
  margin: auto;
  max-width: 1000px;
}

.top-hero {
  align-items: center;
  margin: auto;
  max-width: 1000px;
  padding-top: 20px;
}

.upcoming-shows {
  background: #ffffff;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 1.5rem 0;
}


.show-info-left{
  margin: 1.5rem
}



.show-title {
  padding: .6rem 0;
}

.tag {
  color: #5bb5bf;
  font-family: "Six Caps"
}

.show-selector-box {
  max-width: 1000px;
  margin: auto;
  margin-top: 20px;
  background: white;
  padding: 20px;
}

.menu-link{
  white-space: nowrap;
  font-size: 1.5rem;
}

.react-calendar__tile--now {
  background: "#000000"
}

.markdown {
  color: "red"
}