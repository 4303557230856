@font-face {
  font-family: 'FK Screamer';
  src: local('FFKScreamer-Upright'), url(./fonts/FKScreamer-Upright.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova-Regular'), url(./fonts/ProximaNova-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 700;
  src: local('ProximaNova-Bold'), url(./fonts/ProximaNova-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 600;
  src: local('ProximaNova-Semibold'), url(./fonts/ProximaNova-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'KosanNonCommercial';
  src: local('Kosan'), url(./fonts/KosanNonCommercial.otf) format('opentype');
}

@font-face {
  font-family: 'Shutter Stone Standar';
  src: local('Shutter Stone Standar'), url(./fonts/Shutter Stone Standar.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'Six Caps', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background-color: #000000;
}

img {
  display: block;
  max-width: 100%;
}